@import url('https://fonts.googleapis.com/css?family=Fira+Sans:bold');
@import url('https://fonts.googleapis.com/css?family=PT+Serif:regular');

.App {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10% 0 10%;
  height: 100vh;
}

p {
  font-family: 'Fira Sans';
  font-size: 4rem;
  margin: 0;
}


.leftSide {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-right: solid grey 1px;
  padding: 1rem;
}

.titleContainer {
  animation: titleContainer 200ms ease-in;
  opacity: 0;
  animation-fill-mode: forwards;
}

.leftSide__cvButton {
  animation: titleContainer 200ms ease-in 200ms;
  opacity: 0;
  animation-fill-mode: forwards;
}

.engineer{
  font-family: 'PT Serif';
  font-size: 2rem;
  padding: .5rem
}

button {
  border-radius: 2px;
  border: none;
  color: white;
  background-color:darksalmon;
  padding: .5rem;
  transition: opacity .2s ease, tranform .2s ease;
  font-size: 2vh;

}
button:hover {
  opacity: .7;
}

button:active {
  transform: scale(.98);
}

.rightSide {
  padding: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.avatarImage {
  height: 20rem;
  animation: avatarImage 200ms ease-in 300ms;
  opacity: 0;
  animation-fill-mode: forwards;
}

@keyframes avatarImage {
  0% {
    transform: translateX(-50%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 100;
  }
}

@keyframes titleContainer {
  0% {
    transform: translateY(-50%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 100;
  }
}